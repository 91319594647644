<template>
  <div class="news">
    <navBar :title="route.query.title"></navBar>
    <div class="main">
      <div class="main-left">
        <newItem v-for="(item,index) in list" :key="index" :item="item" class="new-item"></newItem>
      </div>
      <div class="main-right">
        <hot></hot>
      </div>
    </div>
  </div>
</template>

<script setup>
/* 组件 */
import navBar from './component/navBar';
import newItem from './component/newItem';
import hot from './component/hot';

/* 工具 */
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();

/* api */
import { getNews } from '@/api/home'
const list = ref([])

const getList = () => {
  getNews(route.query.id).then(res => {
    list.value = res.data.data
  })
}
getList()
</script>
<style lang="scss" scoped>
.news {
  width: 1200px;
  margin: 0 auto;
  .main{
    display: flex;
    justify-content: space-between;
    .main-left{
      width: 876px;
      margin-right: 32px;
      .new-item{
        padding-bottom: 24px;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(170, 170, 170, 0.2);
      }
      .new-item:last-child{
        border-bottom:none;
      }
    }
  }
}
</style>
