<template>
  <div class="item" @click="toDetail">
    <div class="image"> <img :src="item.cover" class="item-img"/></div>
    <div class="item-info">
      <div class="item-info-title"><span v-if="item.label" class="item-info-lable">{{ item.label }}</span>{{ item.articleTitle }}</div>
      <div class="item-info-content-box">
        <div v-if="item.articleType !== 1" class="item-info-content">{{ escape2Html(getText(item.articleContent)) }}</div>
      </div>
      <div class="item-info-pub">
        <span> {{ item.creator }}</span>
        <span class="item-time">{{ formatTimestamp(item.publishTimeStr) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, toRefs } from "vue"
import { useRouter } from "vue-router";
const router = useRouter();
import { formatTimestamp, escape2Html, getText } from '@/util/validate';

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  }
});
const { item } = toRefs(props);

const toDetail = () => {
  // 链接
  if (item.value.articleType) {
    item.value.articleContent && window.open(item.value.articleContent)
  } else {
    // 新闻
    window.open(router.resolve({ name: 'newsDetail',query: { id: item.value.id }}).href, '_blank')
  }
}

</script>
<style lang="scss" scoped>
.item {
  display:flex;
  justify-content: space-between;
  margin-top: 24px;
  cursor: pointer;
  width: 100%;
 
  .image{
    width: 267px;
    height: 150px;
    margin-right: 24px;
  }
  .item-img{
    width: 266px;
    height: 150px;
    border-radius: 4px;
    
  }
  .item-info{
    font-family: Sans Bold;
    font-size: 18px;
    font-weight: bold;
    color: #1A1A1A;
    flex: 1;
    width: calc(100% - 290px);
    .item-info-title{
      height: 60px;
      line-height: 30px;
      color: #1A1A1A;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; 
      margin-bottom: 8px;

      .item-info-lable{
        padding: 2px 4px;
        box-sizing: border-box;
        background: #FF3737;
        border-radius: 4px;
        line-height: 18px;
        color: #FFFFFF;
        font-size: 12px;
        margin-right: 2px;
        display: inline-block;
        display: inline-block;
      }
    }
    .item-info-content-box{
      height: 48px;
      margin-bottom: 20px;
    }
    .item-info-content{
      height: 48px;
      line-height: 24px;
      font-size: 14px;
      letter-spacing: 0em;
      color: #666;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; 
      :deep blockquote {
        margin:0  !important;
      }
    }
    .item-info-pub{
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 500;
      line-height: 14px;
      color: #9E9E9E;
      .item-time{
        color: #999;
        font-family: Sans Medium;
      }
    }
  }
}
</style>
